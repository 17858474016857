<template>
 <div>
  <editor />
 </div>
</template>

<script>
import editor from "@/components/page/component/Upload.vue";
export default {
 components: {
  editor,
 },
 data: function () {
  return {};
 },
};
</script>

<style scoped>
</style>