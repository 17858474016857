var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "crumbs" },
      [
        _c(
          "el-breadcrumb",
          { attrs: { separator: "/" } },
          [
            _c("el-breadcrumb-item", [
              _c("i", { staticClass: "el-icon-lx-calendar" }),
              _vm._v(" 表单"),
            ]),
            _c("el-breadcrumb-item", [_vm._v("图片上传")]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "content-title" }, [_vm._v("支持拖拽")]),
        _vm._m(0),
        _c(
          "el-upload",
          {
            staticClass: "upload-demo",
            attrs: { drag: "", action: "/api/posts/", multiple: "" },
          },
          [
            _c("i", { staticClass: "el-icon-upload" }),
            _c("div", { staticClass: "el-upload__text" }, [
              _vm._v("将文件拖到此处，或"),
              _c("em", [_vm._v("点击上传")]),
            ]),
            _c(
              "div",
              {
                staticClass: "el-upload__tip",
                attrs: { slot: "tip" },
                slot: "tip",
              },
              [_vm._v("只能上传jpg/png文件，且不超过500kb")]
            ),
          ]
        ),
        _c("div", { staticClass: "content-title" }, [_vm._v("支持裁剪")]),
        _vm._m(1),
        _c("div", { staticClass: "crop-demo" }, [
          _c("img", { staticClass: "pre-img", attrs: { src: _vm.cropImg } }),
          _c("div", { staticClass: "crop-demo-btn" }, [
            _vm._v("选择图片\n                "),
            _c("input", {
              staticClass: "crop-input",
              attrs: { type: "file", name: "image", accept: "image/*" },
              on: { change: _vm.setImage },
            }),
          ]),
        ]),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "裁剪图片",
              visible: _vm.dialogVisible,
              width: "30%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c("vue-cropper", {
              ref: "cropper",
              staticStyle: { width: "100%", height: "300px" },
              attrs: {
                src: _vm.imgSrc,
                ready: _vm.cropImage,
                zoom: _vm.cropImage,
                cropmove: _vm.cropImage,
              },
            }),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.cancelCrop } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = false
                      },
                    },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plugins-tips" }, [
      _vm._v("\n            Element UI自带上传组件。\n            访问地址："),
      _c(
        "a",
        {
          attrs: {
            href: "http://element.eleme.io/#/zh-CN/component/upload",
            target: "_blank",
          },
        },
        [_vm._v("Element UI Upload")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plugins-tips" }, [
      _vm._v(
        "\n            vue-cropperjs：一个封装了 cropperjs 的 Vue 组件。\n            访问地址："
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/Agontuk/vue-cropperjs",
            target: "_blank",
          },
        },
        [_vm._v("vue-cropperjs")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }